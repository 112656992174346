// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../util";
import { useFiefTokenInfo } from "@fief/fief/react";

export default function ManualWaterlLevelMeasurements({
  pointID,
  availablePointIDs,
}) {
  const tokenInfo = useFiefTokenInfo();
  const [data, setData] = useState([]);
  // const [allPointIDs, setAllPointIDs] = useState([]);

  // useEffect(() => {
  //   nmbgmr_getJson(
  //     `authorized/locations/pointids`,
  //     tokenInfo?.access_token,
  //   ).then((data) => {
  //     console.log("data", data);
  //     setAllPointIDs(data);
  //   });
  // }, []);

  useEffect(() => {
    console.log("pointID", pointID);
    if (!availablePointIDs?.includes(pointID)) {
      return;
    }
    nmbgmr_getJson(
      `waterlevels/manual?pointid=${pointID}`,
      tokenInfo?.access_token,
    ).then((data) => {
      console.log("data", data);
      setData(data.reverse());
    });
  }, [pointID]);

  return (
    <div>
      <DataTable
        value={data}
        className={"compact-table"}
        size={"small"}
        stripedRows
        paginator
        rows={10}
        sortMode="multiple"
      >
        <Column header={"Date"} field={"DateMeasured"} sortable />
        <Column header={"Depth to Water"} field={"DepthToWaterBGS"} sortable />
      </DataTable>
    </div>
  );
}
// ============= EOF =============================================
