
import { Timeline } from 'primereact/timeline';
import { PrimeIcons } from 'primereact/api';

const helpContent = [
    {
      status: "Click",
      content:
        "Click on a PVACD map location (point) to view the site's details in a popup.",
      icon: PrimeIcons.EYE,
    },
    {
      status: "Toggle",
      content:
        "Choose a data source to display with the Layers control. The PVACD layer displays sites in the PVACD network, and the USGS layer displays data from the USGS Active Groundwater network. Clicking on a USGS site will navigate to the USGS site page for more details.",
      icon: PrimeIcons.DATABASE,
    },
    {
      status: "Data Table",
      content:
        "Use the Data Table button control on the right to view all PVACD data locations at once with their most recent data.",
      icon: PrimeIcons.TABLE,
    },
    {
      status: "Hydrograph",
      content: (
        <div>
          Click the chart icon
          <i className="pi pi-chart-line" style={{ marginLeft: "4px", marginRight: "4px" }} />
          next to any PVACD site in the data table to view the site's hydrograph. The hydrograph will show trends in water levels over time for manual and continuous measurements.
        </div>
      ),
      icon: PrimeIcons.CHART_LINE,
    },
    {
      status: "Download",
      content:
        "Use the Download Data button at the bottom of the table to download data for selected PVACD locations. If no locations are selected, the button is disabled.",
      icon: PrimeIcons.CLOUD_DOWNLOAD,
    },
    {
      status: "Map Controls",
      content: (
        <>
          Use the map controls to change the basemap style (light, dark, satellite, etc.), zoom in/out, and reset the map view.
        </>
      ),
      icon: PrimeIcons.MAP,
    },
  ];

const PVACDHelp = () => {
    return (
            <Timeline 
                className="m-3" 
                value={helpContent} 
                align="left" 
                content={(helpContent) => (
                    <div>
                        <b>{helpContent.status}</b>
                        <p>{helpContent.content}</p>
                    </div>
                )}
                marker={(helpContent) => (
                    <div className="icon-circle">
                        <i className={`pi ${helpContent.icon}`}></i>
                    </div>
                )}
            />
    );
};

export default PVACDHelp;