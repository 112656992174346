import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import Plot from 'react-plotly.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useFiefTokenInfo } from "@fief/fief/react";


const BERNCOSiteInfoDialog = ({
    berncoSelectedSite,
    showBerncoDialog,
    setShowBerncoDialog,
    berncoPlotData,
    berncoLoading,
    layout,
    setActiveTabIndex, 
    activeTabIndex
  }) => {
    const tokenInfo = useFiefTokenInfo();
    return (
      <Dialog
        header={berncoSelectedSite ? berncoSelectedSite.properties.name : "No Site Selected"}
        visible={showBerncoDialog}
        style={{ width: '70vw' }}
        onHide={() => {
          setShowBerncoDialog(false);
        }}
        maximizable
      >
        <TabView
            activeIndex={activeTabIndex}
            onTabChange={(e) => setActiveTabIndex(e.index)}
        >
          <TabPanel header="Site Information">
            {berncoSelectedSite ? (
              <Card
                title={berncoSelectedSite.properties.name}
                subTitle={'Bernalillo County'}
                footer= {tokenInfo?.access_token && berncoSelectedSite.properties.nmbgmr_id ? (
                    <>
                    <p>NMBGMR point ID found for this site: </p>
                    <Button
                        label="Open NMBGMR Location Details"
                        icon="pi pi-external-link"
                        onClick={() => window.open("/location/" + berncoSelectedSite.properties.nmbgmr_id)}
                        className="p-button-raised p-button-info"
                    />
                    </>
                ) : (
                    null
                )}
              >
                <Message
                  severity="info"
                  text="Check the 'Hydrograph' tab for a detailed water level visualization."
                  style={{ marginBottom: '20px' }}
                />
                {berncoSelectedSite && tokenInfo?.access_token ? (
                    <>
                    <div className="p-field">
                        <strong>NMBGMR ID:</strong>{" "}
                        {berncoSelectedSite.properties.nmbgmr_id || "N/A"}
                    </div>
                    <div className="p-field">
                        <strong>OSE Permit:</strong>{" "}
                        {berncoSelectedSite.properties.ose_permit || "N/A"}
                    </div>
                    <Divider />
                    </> ) : (null)}
                <DataTable value={[berncoSelectedSite]} className="p-datatable-gridlines">
                    <Column header="Latest Manual Water Level (ft bgs)"
                        body={() =>
                            berncoSelectedSite.latestObservations.find((obs) =>
                              obs.datastreamName.includes("Manual")
                            )?.observation?.result?.toFixed(2) || "N/A"
                          }
                    />
                    <Column header="Manual Measurement Date"
                        body={() => {
                            const manualObs = berncoSelectedSite.latestObservations.find((obs) =>
                              obs.datastreamName.includes("Manual")
                            );
                            return manualObs?.observation?.resultTime
                              ? new Date(manualObs.observation.resultTime).toLocaleDateString()
                              : "N/A";
                          }
                        }
                    />
                </DataTable>
              </Card>
            ) : (
              <div>Site not found</div>
            )}
          </TabPanel>
          <TabPanel header="Hydrograph">
            <div
              className="grid justify-content-center relative"
              style={{
                width: '100%',
                height: '520px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <div className="absolute bottom-50 right-50 z-1">
                {berncoLoading && <ProgressSpinner strokeWidth={5} />}
              </div>
              <div style={{ width: '100%', height: '100%' }}>
                <Plot
                  data={berncoPlotData}
                  layout={{
                    ...layout,
                    autosize: true,
                    showlegend: true,  
                    xaxis: {
                      ...layout.xaxis,
                      autorange: true,
                    },
                    legend: {
                      orientation: 'h',
                      yanchor: 'top',
                      y: -0.2,
                      xanchor: 'center',
                      x: 0.5,
                    },
                  }}
                  config={{ responsive: true }}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
    );
  };

export default BERNCOSiteInfoDialog;