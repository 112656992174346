// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { useEffect, useRef } from "react";
import regression from "regression";
import { ProgressSpinner } from "primereact/progressspinner";
import { nmbgmr_getJson } from "../../util";

export default function WellSchematic({
  screens,
  wellData,
  waterLevelData,
  loading,
  trend,
}) {
  const canvasRef = useRef(null);

  const width = 50;
  const height = 500;
  const wellwidth = 25;
  const labelx = width + 10;
  const screenLength = 30;
  const groundSurface = 50;

  useEffect(() => {
    if (waterLevelData.length === 0) {
      return;
    }
    if (wellData === undefined) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.font = "bold 12px Arial";
    ctx.fillStyle = "black";
    ctx.fillText("Not To Scale", 10, 10);

    ctx.fillStyle = "gray";

    ctx.fillRect(width / 2, groundSurface - 10, wellwidth, ctx.canvas.height);

    //draw ground
    ctx.beginPath();
    ctx.moveTo(0, groundSurface);
    ctx.lineTo(ctx.canvas.width, groundSurface);
    ctx.stroke();

    // get the last month of water level data
    let last = waterLevelData[waterLevelData.length - 1];
    // let lastDate = new Date(last.DateMeasured);
    // let priorDate = new Date(
    //   new Date(last.DateMeasured).setDate(lastDate.getDate() - 180),
    // );
    //
    // let ls = waterLevelData.filter((d) => new Date(d.DateMeasured) > priorDate);
    // if (ls.length < 10) {
    //   ls = waterLevelData.slice(Math.max(waterLevelData.length - 10, 0));
    // }
    // // calculate trend
    // let data = ls.map((d, i) => [
    //   new Date(d.DateMeasured).getTime(),
    //   d.DepthToWaterBGS,
    // ]);
    //
    // data = data.map((d) => [(d[0] - data[0][0]) / (24 * 86400), d[1]]);
    // const result = regression.linear(data, { precision: 10 });
    // const trend = result.equation[0];
    // console.log("trend", result, data);

    //draw water surface
    const screenTop = 400;
    const depthToWater = last.DepthToWaterBGS;
    const wellDepth = wellData.well_depth_ftbgs;

    let screenTopft = 100;
    if (screens.length > 0) {
      screenTopft = screens[0].ScreenTop;
    }

    const depthToWaterScaled = (depthToWater / screenTopft) * screenTop;
    ctx.beginPath();
    ctx.moveTo(0, groundSurface + depthToWaterScaled);
    ctx.lineTo(width + wellwidth, groundSurface + depthToWaterScaled);
    ctx.stroke();
    // draw water rect
    ctx.fillStyle = "blue";
    ctx.fillRect(
      width / 2,
      groundSurface + depthToWaterScaled,
      wellwidth,
      ctx.canvas.height,
    );

    // draw water surface label
    ctx.font = "10px Arial";
    ctx.fillStyle = "black";
    ctx.fillText(
      `Water Surface ${depthToWater} (ft)`,
      labelx,
      groundSurface + depthToWaterScaled - 10,
    );

    if (screens.length > 0) {
      let screen = screens[0];
      // draw screen top
      ctx.beginPath();
      ctx.moveTo(width / 2, groundSurface + screenTop);
      ctx.lineTo(width / 2 + wellwidth, groundSurface + screenTop);
      ctx.stroke();
      //console.log("wellscreens", screens);
      // draw screen label
      ctx.font = "10px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(
        `Screen Top ${screen.ScreenTop} (ft)`,
        labelx,
        groundSurface + screenTop - 5,
      );

      // // draw screen bottom
      ctx.beginPath();
      ctx.moveTo(width / 2, groundSurface + screenTop + screenLength);
      ctx.lineTo(
        width / 2 + wellwidth,
        groundSurface + screenTop + screenLength,
      );
      ctx.stroke();
      // draw screen label
      ctx.font = "10px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(
        `Screen Bottom ${screen.ScreenBottom} (ft)`,
        labelx,
        groundSurface + screenTop + screenLength - 5,
      );
    }

    // draw well depth label
    ctx.font = "10px Arial";
    ctx.fillStyle = "black";
    ctx.fillText(`Well Depth ${wellDepth} (ft)`, labelx, canvas.height - 2);

    // draw a house shape
    const hw = 50;
    const ox = 110;
    ctx.beginPath();
    ctx.moveTo(ox, groundSurface);
    ctx.lineTo(ox + hw, groundSurface);
    ctx.lineTo(ox + hw, groundSurface - 20);
    ctx.lineTo(ox + hw / 2, groundSurface - 30);
    ctx.lineTo(ox, groundSurface - 20);
    ctx.closePath();
    ctx.stroke();

    // draw a door on the house
    ctx.beginPath();
    ctx.moveTo(ox + hw / 2 - 5, groundSurface);
    ctx.lineTo(ox + hw / 2 - 5, groundSurface - 12);
    ctx.lineTo(ox + hw / 2 + 5, groundSurface - 12);
    ctx.lineTo(ox + hw / 2 + 5, groundSurface);
    ctx.stroke();

    // draw a window on the house
    ctx.strokeRect(ox + 5, groundSurface - 15, 10, 10);
    ctx.stroke();

    // draw a window on the house
    ctx.strokeRect(ox + 35, groundSurface - 15, 10, 10);
    ctx.stroke();

    // draw a trend indicator
    if (trend !== undefined) {
      drawTrend(ctx);
    }
  }, [screens, wellData, waterLevelData, trend]);

  const drawTrend = (ctx) => {
    //console.log("asdfasdf", trend);
    let color = "red";
    const arrowWidth = 10;
    const arrowHeight = 20;
    const trendX = 100;
    const trendHeight = 100;
    let labely = 100;
    // if (Math.abs(trend) < 0.25) {
    //   return;
    // } else if (trend < 0.25) {

    if (trend.trend === "Stable") {
      return;
    } else if (trend.trend === "Decreasing") {
      color = "#0042f7";

      // draw arrow at the top
      ctx.beginPath();
      ctx.moveTo(trendX - arrowWidth, height / 2);
      ctx.lineTo(trendX, height / 2 - arrowHeight);
      ctx.lineTo(trendX + arrowWidth, height / 2);
      // ctx.lineTo(110, height / 2 + 1);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();

      labely = height / 2 - arrowHeight - 12;
    } else {
      color = "#fa6902";

      // draw arrow at the bottom
      ctx.beginPath();
      ctx.moveTo(trendX - arrowWidth, trendHeight + height / 2);
      ctx.lineTo(trendX, trendHeight + height / 2 + arrowHeight);
      ctx.lineTo(trendX + arrowWidth, trendHeight + height / 2);
      labely = trendHeight + height / 2 + arrowHeight + 12;

      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    }
    ctx.fillStyle = color;
    ctx.fillRect(trendX - 5, height / 2, 10, trendHeight);

    // draw trend value
    ctx.font = "10px Arial";
    ctx.fillStyle = "black";
    if (trend.slope !== null && trend.slope !== undefined) {
      ctx.fillText(
        `Trend: ${trend.slope.toFixed(2)} (ft/year)`,
        labelx,
        labely,
      );
    }
  };

  return (
    <div className={"flex justify-content-center"}>
      {loading && (
        <ProgressSpinner
          strokeWidth={3}
          className={"absolute justify-content-center"}
        />
      )}
      <canvas ref={canvasRef} width={200} height={height} />
    </div>
  );
}
// ============= EOF =============================================
