
import { Timeline } from 'primereact/timeline';
import { PrimeIcons } from 'primereact/api';
import polygontoolimg from '../../../img/PolygonTool.png';

const helpContent = [
    {
      status: "Click",
      content:
        "Click on a map location (point) to view the site's details in a popup.",
      icon: PrimeIcons.EYE,
    },
    {
      status: "Toggle",
      content:
        "Choose a data source to display with the Data Layers control. More information about each is available within the layer control.",
      icon: PrimeIcons.DATABASE,
    },
    {
      status: "Polygon Tool",
      content: (
        <>
        Use the polygon tool to draw a shape on the map and view data for that area in the Data Table. Click the polygon icon <img
            src={polygontoolimg}
            alt="Polygon Tool Icon"
            style={{
              width: "1.2rem",
              marginLeft: "4px",
              marginRight: "4px",
              verticalAlign: "middle",
            }}
          /> to start drawing, and double-click to finish.
        </>
      ),
      icon: PrimeIcons.STOP,
    },
    {
      status: "Data Table",
      content:
        "Use the Data Table to view the sites you selected using the polygon tool.",
      icon: PrimeIcons.TABLE,
    },
    {
      status: "Hydrograph",
      content: (
        <div>
          Click the chart icon
          <i className="pi pi-chart-line" style={{ marginLeft: "4px", marginRight: "4px" }} />
          next to any site in the data table to view the site's hydrograph. The hydrograph will show trends in water levels over time for manual and continuous measurements. This feature may not be available for every data layer.
        </div>
      ),
      icon: PrimeIcons.CHART_LINE,
    },
    {
      status: "Download",
      content:
        "Data download features are coming soon. Check back later for updates.",
      icon: PrimeIcons.CLOUD_DOWNLOAD,
    },
    {
      status: "Map Controls",
      content: (
        <>
          Use the map controls to change the basemap style (light, dark, satellite, etc.), zoom in/out, and reset the map view.
        </>
      ),
      icon: PrimeIcons.MAP,
    },
  ];

const GWHelp = () => {
    return (
            <Timeline 
                className="m-3" 
                value={helpContent} 
                align="left" 
                content={(helpContent) => (
                    <div>
                        <b>{helpContent.status}</b>
                        <p>{helpContent.content}</p>
                    </div>
                )}
                marker={(helpContent) => (
                    <div className="icon-circle">
                        <i className={`pi ${helpContent.icon}`}></i>
                    </div>
                )}
            />
    );
};

export default GWHelp;