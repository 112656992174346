import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import Plot from 'react-plotly.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useFiefTokenInfo } from "@fief/fief/react";


const EBIDSiteInfoDialog = ({
    ebidSelectedSite,
    showEbidDialog,
    setshowEbidDialog,
    ebidPlotData,
    ebidLoading,
    layout,
    setActiveTabIndex, 
    activeTabIndex
  }) => {
    const tokenInfo = useFiefTokenInfo();
    //console.log("ebidSelectedSite", ebidSelectedSite);
    return (
      <Dialog
        header={ebidSelectedSite ? ebidSelectedSite.properties.name : "No Site Selected"}
        visible={showEbidDialog}
        style={{ width: '70vw' }}
        onHide={() => {
          setshowEbidDialog(false);
        }}
        maximizable
      >
        <TabView
            activeIndex={activeTabIndex}
            onTabChange={(e) => setActiveTabIndex(e.index)}
        >
          <TabPanel header="Site Information">
            {ebidSelectedSite ? (
              <Card
                title={ebidSelectedSite.properties.name}
                subTitle={'Elephant Butte Irrigation District'}
                // footer= {tokenInfo?.access_token && ebidSelectedSite.properties.nmbgmr_id ? (
                //     <>
                //     <p>NMBGMR point ID found for this site: </p>
                //     <Button
                //         label="Open NMBGMR Location Details"
                //         icon="pi pi-external-link"
                //         onClick={() => window.open("/location/" + ebidSelectedSite.properties.nmbgmr_id)}
                //         className="p-button-raised p-button-info"
                //     />
                //     </>
                // ) : (
                //     null
                // )}
              >
                <Message
                  severity="info"
                  text="Check the 'Hydrograph' tab for a detailed water level visualization."
                  style={{ marginBottom: '20px' }}
                />
                {/* {ebidSelectedSite && tokenInfo?.access_token ? (
                    <>
                    <div className="p-field">
                        <strong>NMBGMR ID:</strong>{" "}
                        {ebidSelectedSite.properties.nmbgmr_id || "N/A"}
                    </div>
                    <div className="p-field">
                        <strong>OSE Permit:</strong>{" "}
                        {ebidSelectedSite.properties.ose_permit || "N/A"}
                    </div>
                    <Divider />
                    </> ) : (null)} */}
                <DataTable value={[ebidSelectedSite]} className="p-datatable-gridlines">
                    <Column header="Latest Groundwater Level (ft bgs)"
                        body={() =>
                            ebidSelectedSite.latestObservations.find((obs) =>
                              obs.datastreamName.includes("Groundwater")
                            )?.observation?.result?.toFixed(2) || "N/A"
                          }
                    />
                    <Column header="Latest Measurement Date"
                        body={() => {
                            const manualObs = ebidSelectedSite.latestObservations.find((obs) =>
                              obs.datastreamName.includes("Groundwater")
                            );
                            return manualObs.observation?.resultTime
                              ? new Date(manualObs.observation.resultTime).toLocaleDateString()
                              : "N/A";
                          }
                        }
                    />
                    {/* <Column header="Latest Continuous Water Level (ft bgs)"
                        body={() =>
                            ebidSelectedSite.latestObservations.find((obs) =>
                              obs.datastreamName.includes("Continuous")
                            )?.observation.result.toFixed(2) || "N/A"
                          }
                    />
                    <Column header="Continuous Measurement Date"
                        body={() => {
                            const continuousObs = ebidSelectedSite.latestObservations.find((obs) =>
                              obs.datastreamName.includes("Continuous")
                            );
                            return continuousObs
                              ? new Date(continuousObs.observation.resultTime).toLocaleDateString()
                              : "N/A";
                          }
                        }
                    /> */}
                </DataTable>
              </Card>
            ) : (
              <div>Site not found</div>
            )}
          </TabPanel>
          <TabPanel header="Hydrograph">
            <div
              className="grid justify-content-center relative"
              style={{
                width: '100%',
                height: '520px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <div className="absolute bottom-50 right-50 z-1">
                {ebidLoading && <ProgressSpinner strokeWidth={5} />}
              </div>
              <div style={{ width: '100%', height: '100%' }}>
                <Plot
                  data={ebidPlotData}
                  layout={{
                    ...layout,
                    autosize: true,
                    showlegend: true,  
                    xaxis: {
                      ...layout.xaxis,
                      autorange: true,
                    },
                    legend: {
                      orientation: 'h',
                      yanchor: 'top',
                      y: -0.2,
                      xanchor: 'center',
                      x: 0.5,
                    },
                  }}
                  config={{ responsive: true }}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
    );
  };

export default EBIDSiteInfoDialog;