import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import Plot from 'react-plotly.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

const SiteInfoDialog = ({
  hydrographSelectedSite,
  showHydrograph,
  setShowHydrograph,
  plotData,
  loading,
  layout,
  activeTabIndex,
  setActiveTabIndex,
  toggleDataTable,
  setMultiHydrographSites,
  setShowMultiHydrograph,
  plot_hygrogaphs,
  selectedLocations,
}) => {
  return (
    <Dialog
      header={hydrographSelectedSite ? hydrographSelectedSite[0].properties.name : "No Site Selected"}
      visible={showHydrograph}
      style={{ width: '70vw' }}
      onHide={() => {
        setShowHydrograph(false);
        setActiveTabIndex(0);
      }}
      maximizable
      footer={
        <>
          {/* <Button
            label="Toggle Data Table"
            icon="pi pi-table"
            onClick={toggleDataTable}
            className="button p-button-raised p-button-info"
            tooltip="Show Data Table"
            tooltipOptions={{ position: 'left' }}
          /> */}
          <Button
            className="ml-2"
            rounded
            label="Plot Multiple PVACD Sites"
            icon="pi pi-chart-line"
            severity="warning"
            tooltip="Plot Multi-site Hydrograph"
            onClick={() => {
              setMultiHydrographSites(selectedLocations);
              setShowMultiHydrograph(true);
              plot_hygrogaphs(selectedLocations, 'multi');
            }}
          />
        </>
      }
    >
      <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
        <TabPanel header="Site Information">
          {hydrographSelectedSite && hydrographSelectedSite[0].latestObservations ? (
            <Card
              title={hydrographSelectedSite[0].properties.name}
              subTitle={'Pecos Valley Artesian Conservancy District (PVACD)'}
            >
              <Message
                severity="info"
                text="Check the 'Hydrograph' tab for a detailed water level visualization."
                style={{ marginBottom: '20px' }}
              />
              <div className="p-field">
                <strong>Well Depth:</strong>{" "}
                {hydrographSelectedSite[0].properties.well_depth.value}{" "}
                {hydrographSelectedSite[0].properties.well_depth.unit}
              </div>
              <div className="p-field">
                <strong>Hole Depth:</strong>{" "}
                {hydrographSelectedSite[0].properties.hole_depth.value}{" "}
                {hydrographSelectedSite[0].properties.hole_depth.unit}
              </div>
              <Divider />
              <DataTable value={[hydrographSelectedSite[0]]} className="p-datatable-gridlines">
                <Column
                  header="Latest Manual Water Level (ft bgs)"
                  body={() =>
                    hydrographSelectedSite[0].latestObservations.find((obs) =>
                      obs.datastreamName.includes("Manual")
                    )?.observation.result || "N/A"
                  }
                />
                <Column
                  header="Manual Measurement Date"
                  body={() => {
                    const manualObs = hydrographSelectedSite[0].latestObservations.find((obs) =>
                      obs.datastreamName.includes("Manual")
                    );
                    return manualObs
                      ? new Date(manualObs.observation.resultTime).toLocaleDateString()
                      : "N/A";
                  }}
                />
                <Column
                  header="Latest Continuous Water Level (ft bgs)"
                  body={() =>
                    hydrographSelectedSite[0].latestObservations.find((obs) =>
                      !obs.datastreamName.includes("Manual")
                    )?.observation.result.toFixed(2) || "N/A"
                  }
                />
                <Column
                  header="Continuous Measurement Date"
                  body={() => {
                    const continuousObs = hydrographSelectedSite[0].latestObservations.find((obs) =>
                      !obs.datastreamName.includes("Manual")
                    );
                    return continuousObs
                      ? new Date(continuousObs.observation.resultTime).toLocaleDateString()
                      : "N/A";
                  }}
                />
              </DataTable>
            </Card>
          ) : (
            hydrographSelectedSite ? (
            <Card
              title={hydrographSelectedSite[0].properties.name}
              subTitle={'Pecos Valley Artesian Conservancy District (PVACD)'}
            >
              <Message
                severity="info"
                text="Check the 'Hydrograph' tab for a detailed water level visualization."
                style={{ marginBottom: '20px' }}
              />
              <div className="p-field">
                <strong>Well Depth:</strong>{" "}
                {hydrographSelectedSite[0].properties.well_depth.value}{" "}
                {hydrographSelectedSite[0].properties.well_depth.unit}
              </div>
              <div className="p-field">
                <strong>Hole Depth:</strong>{" "}
                {hydrographSelectedSite[0].properties.hole_depth.value}{" "}
                {hydrographSelectedSite[0].properties.hole_depth.unit}
              </div>
            </Card>
            ) :
            (
              <div>Site not found</div>
            )
          )}
        </TabPanel>
        <TabPanel header="Hydrograph">
          <div
            className="grid justify-content-center relative"
            style={{
              width: '100%',
              height: '520px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div className="absolute bottom-50 right-50 z-1">
              {loading && <ProgressSpinner strokeWidth={5} />}
            </div>
            <div style={{ width: '100%', height: '100%' }}>
              <Plot
                data={plotData}
                layout={{
                  ...layout,
                  autosize: true,
                  xaxis: {
                    ...layout.xaxis,
                    range: [new Date("2021-01-01"), new Date()],
                    autorange: false,
                  },
                  legend: {
                    orientation: 'h',
                    yanchor: 'top',
                    y: -0.2,
                    xanchor: 'center',
                    x: 0.5,
                  },
                }}
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

export default SiteInfoDialog;
