// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import "mapbox-gl/dist/mapbox-gl.css";
import "./GWMap.css";

//Draw control imports
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import GWDrawControl from "./GWDrawControl";
import { useControl } from "react-map-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useRef, useCallback } from "react";
import * as turf from "@turf/turf";

import { settings } from "../../../settings";
import { Layer, Map, Popup, Source, NavigationControl } from "react-map-gl";
import MapStyleControl from "./GWMapStyleControl";
import DataTableControl from "./GWDataTableControl";
import GWHelp from "./GWHelp";
import { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import MapTitleControl from "./GWMapTitleControl";
import LayerControl from "./GWLayerControl";
import { Sidebar } from "primereact/sidebar";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { mToFt } from "../../../util";

import Light from "../../../img/basemap/Light.png";
import Dark from "../../../img/basemap/Dark.png";
import Outdoors from "../../../img/basemap/Outdoors.png";
import SatelliteStreets from "../../../img/basemap/SatelliteStreets.png"; 

import { isTouchDevice } from "../../../util";
import { ProgressBar } from "primereact/progressbar";

const MAP_STYLES = [
    { label: "Light", value: "mapbox://styles/mapbox/light-v10", thumbnail: Light },
    { label: "Dark", value: "mapbox://styles/mapbox/dark-v10", thumbnail: Dark },
    { label: "Outdoors", value: "mapbox://styles/mapbox/outdoors-v12", thumbnail: Outdoors },
    { label: "Satellite", value: "mapbox://styles/mapbox/satellite-streets-v12", thumbnail: SatelliteStreets },
    ];

export default function GWMap({
  mapRef,
  initialViewState,
  style,
  sources,
  sourceData,
  setSourceData,
  popupContent,
  setPopupContent,
  onMapClickCallback,
  selectedLocations,
  toggleDataTable,
  onSelectedFeatureChange,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedmapStyle, setSelectedMapStyle] = useState(() => {
    const theme = localStorage.getItem('theme') || 'light';
    return theme === 'dark' ? MAP_STYLES[1].value : MAP_STYLES[0].value;
  });

  //state managment for map info control
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);  
  
  //state management for draw control
  const [isdrawing, setIsDrawing] = useState(false);
  const [selectionPolygons, setSelectionPolygons] = useState({});
  const drawRef = useRef(null);

  //State management for data layer visibility
  const [layerVisibility, setLayerVisibility] = useState({
    "weaver:gw:nmbgmr": true,
    "weaver:gw:pvacd": false,
    "weaver:gw:usgs": false,
  });

  //handle data layer selection via checkbox
  const handleLayerSelection = (layerId, isChecked) => {
    setLayerVisibility((prev) => {
      return {
        ...prev,
        [layerId]: isChecked,
      };
    });
  };

  //Open layer control on map load
  const [openLayerControlonLoad, setOpenLayerControlonLoad] = useState(true);

  const toggleInfoSidebar = () => {
    setShowInfoSidebar(prev => !prev);
    };

  //console log polygon selection
  useEffect(() => {
    //console.log("polygon selection", selectionPolygons);
  }, [selectionPolygons]);

  //Process selected polygon features and send to onSelectedFeatureChange callback
  useEffect(() => {
    const selectedFeatures = getSelectedFeatures(sourceData, selectionPolygons, layerVisibility);
    //console.log("selected features", selectedFeatures);
  
  //callback to parent component - pass selected features up to dashboard
    if (onSelectedFeatureChange) {
      onSelectedFeatureChange(selectedFeatures);
    }
  }, [selectionPolygons, layerVisibility]);

  // Draw control event handlers
  //onUpdate
  const onUpdate = useCallback((e) => {
    setSelectionPolygons((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);
  
  //onDelete
  const onDelete = useCallback((e) => {
    setSelectionPolygons((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
    setIsDrawing(false);
  }, []);
  
  //OnModeChange
  const onModeChange = useCallback((e) => {
    //console.log("mode", e);
    setIsDrawing(e.mode === "draw_polygon");
  }, []);
  
  //OnSelectionChange
  const onSelectionChange = useCallback((e) => {
    //console.log("selection change", e);
    if (drawRef.current && drawRef.current.getMode() === "draw_polygon") {
      setIsDrawing(true);
    } else {
      setIsDrawing(false);
    }
  }, []);  

  //extract selected features from selectionPolygons
  const extractFeatures = (sourceData, searchPolygon, layerVisibility) => {
    let selected = [];
    if (!searchPolygon) {
      return selected;
    }
    for (let [key, source] of Object.entries(sourceData)) {
      //console.log("source", source);
      if (!layerVisibility[key]) {
        continue;
      }
      let filteredFeatures = source.features.filter((f) =>
        turf.booleanPointInPolygon(f.geometry, searchPolygon),
      );
      let ff = filteredFeatures.map((feature) => {
        //console.log("source properties", source.properties);
        return {
          ...feature,
          data_source_short: source.properties.label,
          data_source: source.properties.longLabel,
          homeLink: source.properties.homeLink,
        };
      });
      selected.push(...ff);
      //console.log("extracted features", selected);
    }
    return selected;
  };

  const getSelectedFeatures = (sourceData, selectionPolygons, layerVisibility) => {
    let allFeatures = [];
    if (Object.keys(selectionPolygons).length > 0) {
      for (const [key, polygon] of Object.entries(selectionPolygons)) {
        allFeatures.push(...extractFeatures(sourceData, polygon, layerVisibility));
        //console.log("all features", allFeatures);
      }
    }
    return allFeatures;
  };

  // Update the feature state for selected locations - to use with styling of points conditional on selection
  useEffect(() => {
    if (!mapRef || !mapRef.current || !selectedLocations) return;
  
    const map = mapRef.current;
  
    function updateFeatureStates() {
      sources.forEach((source) => {
        const sourceId = source.id;
  
        const mapSource = map.getSource(sourceId);
        if (!mapSource || !mapSource._data || !mapSource._data.features) return;
  
        mapSource._data.features.forEach((feature) => {
          map.setFeatureState(
            { source: sourceId, id: feature.id },
            { selected: false }
          );
        });
  
        selectedLocations.forEach((location) => {
          map.setFeatureState(
            { source: sourceId, id: location.id },
            { selected: true }
          );
        });
      });
    }
  
    if (!map.isStyleLoaded()) {
      map.once('styledata', updateFeatureStates);
    } else {
      updateFeatureStates();
    }
  }, [selectedLocations, mapRef, sources]);

  useEffect(() => {
    if (!setSourceData) return;

    setLoading(true);

    const fetchPromises = sources.map((source) => {
      if (sourceData[source.id]) return Promise.resolve();
          
        return source.dataFetcher().then((data) => {
          if (!data) return;
          data.features = data.features.map((feature, index) => {
            const id = feature.id || feature.properties.name || index;
            return {
              //add id to feature to make sure it can match table selection
              ...feature,
              id: id,
              properties: {
                ...feature.properties,
                id: id,
              },
            };
          });
  
          data["properties"] = { 
            ...source,
          };
          setSourceData((prevData) => ({ ...prevData, [source.id]: data }));
        });
    });
    
      Promise.all(fetchPromises).then(() => {
      setLoading(false);
    });
  }, [sources, setSourceData]);

  const layers = sources.map((source) => {
    if (!sourceData[source.id]) return null;
    if (!layerVisibility[source.id]) return null;

    return (
      <Source
        key={source.id}
        id={source.id}
        type="geojson"
        data={sourceData[source.id]}
        promoteId="id"
      >
        <Layer
          id={source.id}
          type={source.layer_type || "circle"}
          paint={{
            "circle-radius": 4.25,
            "circle-color": [
              "case",
              ["boolean", ["feature-state", "selected"], false],
              "white", // for selected sites
              source.paint["circle-color"] || "rgba(255, 255, 255, 0.9)"  //for non-selected sites
            ],
            "circle-stroke-width": 1.5,
            "circle-stroke-color": "#000000"
          }}
          filter={["==", "$type", "Point"]}
        />
      </Source>
    );
  });

  const onMapClick = (e) => {
    //handle drawing
    if (isdrawing) return;

    if (!mapRef || !mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point);
    const sourceIds = sources.map((s) => s.id);
    const clickedFeatures = features.filter(
      (f) => f.layer && sourceIds.includes(f.layer.source)
    );

    if (clickedFeatures.length === 0) {
      setPopupContent(undefined);
      return;
    }

    const feature = clickedFeatures[0];
    if (onMapClickCallback) {
      onMapClickCallback(e, clickedFeatures);
    } else {
      setPopupContent({
        coordinates: feature.geometry.coordinates,
        children: (
          <h2>
            <strong>{feature.properties.name}</strong>
          </h2>
        ),
      });
    }
  };

  const onMouseMove = (e) => {
    //handle drawing
    if (isdrawing) return;

    if (!mapRef || !mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point);
    const sourceIds = sources.map((s) => s.id);
    const hoveredFeatures = features.filter(
      (f) => f.layer && sourceIds.includes(f.layer.source)
    );
    //console.log("hovered", hoveredFeatures);

    if (hoveredFeatures.length > 0) {
        mapRef.current.getCanvas().style.cursor = "pointer";
    }
    else {
        mapRef.current.getCanvas().style.cursor = "grab";
    }

    if (hoveredFeatures.length === 0) {
      setPopupContent(undefined);
      return;
    }

    //Helper functions for Thing expansion
    const getThingProperties = (f) => {
      try {
        if (f.properties && f.properties.thing) {
          const thing = JSON.parse(f.properties.thing);
          return thing.properties || {};
        }
        return {};
      } catch (error) {
        console.error("Error parsing thing:", error);
        return {};
      }
    };
    const getProperty = (f, propName) => {
      const thingProps = getThingProperties(f);
      return f.properties[propName] || thingProps[propName] || "";
    };

    const popupData = [
      { label: "Name", values: hoveredFeatures.map((f) => getProperty(f, 'name')) },
      { label: "Elevation (ft)", values: hoveredFeatures.map((f) => getProperty(f, 'elevation_ft')) },
      { label: "Well Depth (ft)", values: hoveredFeatures.map((f) => getProperty(f, 'well_depth_ftbgs')) },
      { label: "Hole Depth (ft)", values: hoveredFeatures.map((f) => getProperty(f, 'hole_depth_ftbgs')) },
      { label: "Site ID", values: hoveredFeatures.map((f) => getProperty(f, 'site_id') || getProperty(f, 'id')) },
      { label: "OSE Well ID", values: hoveredFeatures.map((f) => getProperty(f, 'ose_well_id')) },
      { label: "Alternate Site ID", values: hoveredFeatures.map((f) => getProperty(f, 'alternate_site_id')) },
      { label: "Site No", values: hoveredFeatures.map((f) => getProperty(f, 'site_no')) },
    ];

    const featureColumns = hoveredFeatures.map((_, index) => ({
      body: (rowData) => <span>{rowData.values[index]}</span>,
    }));
  
    setPopupContent({
      coordinates: hoveredFeatures[0].geometry.coordinates,
      children: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 style={{ color: 'black', textAlign: 'center' }}>Click for more details</h3>
          <DataTable header={false} value={popupData} size="small" stripedRows showGridlines={true} style={{ width: "100%"}}>
            <Column field="label" style={{ fontWeight: 'bold' }}></Column>
            {featureColumns.map((col) => (
            <Column key={col.field} field={col.field} header={col.header} body={col.body}></Column>
          ))}
          </DataTable>
        </div>
      ),
    });
  };

  const sidebarWidth = isTouchDevice() ? "100vw" : "33vw";

  return (
    <div className="map-container">
      <Map
        ref={mapRef}
        initialViewState={initialViewState}
        style={style}
        mapStyle={selectedmapStyle}
        mapboxAccessToken={settings.mapbox.token}
        onClick={onMapClick}
        onMouseMove={onMouseMove}
        onTouchEnd={onMapClick}
      >
        {layers}
        {popupContent && (
          <Popup
            latitude={popupContent.coordinates[1]}
            longitude={popupContent.coordinates[0]}
            closeButton={false}
            closeOnClick
            maxWidth={500}
            onClose={() => setPopupContent(undefined)}
          >
            {popupContent.children}
          </Popup>
        )}
        <NavigationControl position="top-right" />
        <GWDrawControl
            drawRef={drawRef}
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
              combine_features: true,
              uncombine_features: true,
            }}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onModeChange={onModeChange}
            onSelectionChange={onSelectionChange}
            position="top-right"
          />

        <div className="map-title-control-container">
            <MapTitleControl onClick={toggleInfoSidebar}/>
        </div>

        <div className="map-style-control-container">
        <MapStyleControl
            styles={MAP_STYLES}
            currentStyle={selectedmapStyle}
            onChange={(style) => setSelectedMapStyle(style)}
        />
        </div>
        <div className="data-table-control-container">
          <DataTableControl onClick={toggleDataTable} />
        </div>

        <div className="layer-control-container">
          <LayerControl
            layers={sources}
            layerVisibility={layerVisibility}
            handleLayerSelection={handleLayerSelection}
            openOnLoad={openLayerControlonLoad}
          />
        </div>
      </Map>

      <Sidebar visible={showInfoSidebar} onHide={() => setShowInfoSidebar(false)} position="left" style={{ width: sidebarWidth }}>
        <Card 
          title="NM Groundwater Dashboard"
          subTitle="Information & Help"
        >
          <GWHelp />
        </Card>
      </Sidebar>

      {loading && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional overlay
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <ProgressSpinner strokeWidth="5" />
      </div>
    )}
    </div>
  );
}