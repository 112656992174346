import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const NavDialog = ({ visible, onHide, onConfirm, siteno }) => {
  return (
    <Dialog
      header="Navigate Away From Weaver?"
      visible={visible}
      modal
      onHide={onHide}
      footer={
        <div>
          <Button label="No" icon="pi pi-times" onClick={onHide} className="p-button-text" />
          <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
        </div>
      }
    >
      <p>Are you sure you want to leave Weaver and go to the USGS website for site number: {siteno}?</p>
    </Dialog>
  );
};

export default NavDialog;