import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import Plot from 'react-plotly.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

import LocationDetail from '../../../LocationDetail/LocationDetail';
import AMPAPIHydrograph from '../../../LocationDetail/AMPAPIHydrograph';

const NmbgmrSiteInfoDialog = ({
    nmbgmrSelectedSite,
    showNmbgmrDialog,  
    setShowNmbgmrDialog,
    setActiveTabIndex, 
    activeTabIndex
  }) => {
    const selectedSite = nmbgmrSelectedSite ? nmbgmrSelectedSite.properties : null;
    //console.log('selectedSite', selectedSite);
  
    return (
      <Dialog
        header={selectedSite ? selectedSite.name : "No Site Selected"}
        visible={showNmbgmrDialog}
        style={{ width: "70vw" }}
        onHide={() => {
          setShowNmbgmrDialog(false);
          setActiveTabIndex(0);
        }}
        maximizable
        footer={
          selectedSite && selectedSite.location_url ? (
            <Button
              label="Open Location Details in New Window"
              icon="pi pi-external-link"
              onClick={() => window.open("/location/" + selectedSite.point_id)}
              className="p-button-raised p-button-info"
            />
          ) : null
        }
      >
       {selectedSite ? (
        selectedSite.site_type === 'Groundwater other than spring (well)' ? (
        <TabView
            activeIndex={activeTabIndex}
            onTabChange={(e) => setActiveTabIndex(e.index)}
        >
            <TabPanel header="Site Information">
                <Card title={selectedSite.name} subTitle={`New Mexico Bureau of Geology and Mineral Resources (NMBGMR)`}>
                  <Message
                    severity="info"
                    text="Check the 'Hydrograph' tab for a detailed water level visualization."
                    style={{ marginBottom: '20px' }}
                  />
                    <div className='p-field'>
                        <strong>Site Type:</strong> {selectedSite.site_type || ""}
                    </div>

                    <Divider />

                     {/* <div className="p-field">
                        <strong>Well Depth:</strong>{" "}
                        {selectedSite.thing &&
                        selectedSite.thing.properties &&
                        selectedSite.thing.properties.well_depth_ftbgs != null
                          ? `${selectedSite.thing.properties.well_depth_ftbgs} (ft bgs)`
                          : ""}
                      </div>

                      <div className="p-field">
                        <strong>Hole Depth:</strong>{" "}
                        {selectedSite.thing &&
                        selectedSite.thing.properties &&
                        selectedSite.thing.properties.hole_depth_ftbgs != null
                          ? `${selectedSite.thing.properties.hole_depth_ftbgs} (ft bgs)`
                          : ""}
                      </div> */}

                      <div className="p-field">
                        <strong>Elevation (ft):</strong> {selectedSite.elevation_ft || ""}
                      </div>

                      <div className="p-field">
                        <strong>Elevation Method:</strong> {selectedSite.elevation_method || ""}
                      </div>

                      {/* <div className="p-field">
                        <strong>Formation:</strong>{" "}
                        {selectedSite.thing &&
                        selectedSite.thing.properties &&
                        selectedSite.thing.properties.formation || ""}
                      </div> */}

                      {/* <div className="p-field">
                        <strong>OSE Well ID:</strong>{" "}
                        {selectedSite.thing &&
                        selectedSite.thing.properties &&
                        selectedSite.thing.properties.ose_well_id || ""}
                      </div> */}

                      <div className="p-field">
                        <strong>Site ID:</strong> {selectedSite.site_id || selectedSite.point_id || ""}
                      </div>

                      <div className="p-field">
                        <strong>Alternate Site ID:</strong> {selectedSite.alternate_site_id || ""}
                      </div>

                      <div className="p-field">
                        <strong>Location Datum:</strong> {selectedSite.lonlat_datum || ""}
                      </div>

                      <div className="p-field">
                        <strong>UTM Datum:</strong> {selectedSite.utm_datum || ""}
                      </div>

                      <div className="p-field">
                        <strong>Altitude Datum:</strong> {selectedSite.altitude_datum || ""}
                      </div>

                      <Divider />

                      <div className="p-field">
                        <strong>Easting:</strong> {selectedSite.easting || ""}
                      </div>

                      <div className="p-field">
                        <strong>Northing:</strong> {selectedSite.northing || ""}
                      </div>
        
                </Card>
            </TabPanel>
            <TabPanel header="Hydrograph">
                <AMPAPIHydrograph
                    pointId={selectedSite.point_id}
                    showTable={true}
                />
            </TabPanel>
        </TabView>
        ) : (
          <TabView
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
           >
          <TabPanel header="Site Information">
              <Card title={selectedSite.name} subTitle={`New Mexico Bureau of Geology and Mineral Resources (NMBGMR)`}>
                <Message
                  severity="info"
                  text="Check the 'Hydrograph' tab for a detailed water level visualization."
                  style={{ marginBottom: '20px' }}
                />
                  <div className='p-field'>
                      <strong>Site Type:</strong> {selectedSite.site_type || ""}
                  </div>

                  <Divider />

                    <div className="p-field">
                      <strong>Elevation (ft):</strong> {selectedSite.elevation_ft || ""}
                    </div>

                    <div className="p-field">
                      <strong>Elevation Method:</strong> {selectedSite.elevation_method || ""}
                    </div>

                    {/* <div className="p-field">
                      <strong>Formation:</strong>{" "}
                      {selectedSite.thing &&
                      selectedSite.thing.properties &&
                      selectedSite.thing.properties.formation || ""}
                    </div> */}

                    <div className="p-field">
                      <strong>Site ID:</strong> {selectedSite.site_id || selectedSite.point_id || ""}
                    </div>

                    <div className="p-field">
                      <strong>Alternate Site ID:</strong> {selectedSite.alternate_site_id || ""}
                    </div>

                    <div className="p-field">
                      <strong>Location Datum:</strong> {selectedSite.lonlat_datum || ""}
                    </div>

                    <div className="p-field">
                      <strong>UTM Datum:</strong> {selectedSite.utm_datum || ""}
                    </div>

                    <div className="p-field">
                      <strong>Altitude Datum:</strong> {selectedSite.altitude_datum || ""}
                    </div>

                    <Divider />

                    <div className="p-field">
                      <strong>Easting:</strong> {selectedSite.easting || ""}
                    </div>

                    <div className="p-field">
                      <strong>Northing:</strong> {selectedSite.northing || ""}
                    </div>
      
              </Card>
          </TabPanel>
          <TabPanel header="Hydrograph">
              <AMPAPIHydrograph
                  pointId={selectedSite.point_id}
                  showTable={true}
              />
          </TabPanel>
      </TabView>
        )
        ): (
          <div>No Site selected</div>
        )}
      </Dialog>
    );
  };
  
  export default NmbgmrSiteInfoDialog;