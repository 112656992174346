// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../../util";
import { useFiefTokenInfo } from "@fief/fief/react";
import { Button } from "primereact/button";

export default function OwnersWells({ pointId }) {
  const [wells, setWells] = useState([]);
  const [loading, setLoading] = useState(true);
  const tokenInfo = useFiefTokenInfo();

  const auth_api_getJson = (url) => {
    return nmbgmr_getJson(url, tokenInfo?.access_token);
  };

  useEffect(() => {
    auth_api_getJson(`authorized/locations/associated?pointid=${pointId}`).then(
      (data) => {
        //console.log(data);
        setWells(data);
        setLoading(false);
      },
    );
  }, [pointId]);

  return (
    <DataTable
      value={wells}
      stripedRows
      rows={10}
      className={"compact-table"}
      size={"small"}
    >
      <Column field={"PointID"} header={"PointID"} />
      <Column field={"SiteNames"} header={"Site Names"} />
      <Column field={"SiteID"} header={"Site ID"} />
      <Column field={"Easting"} header={"Easting"} />
      <Column field={"Northing"} header={"Northing"} />
      <Column
        field={"Elevation"}
        header={"Elevation (ft)"}
        body={(rowData) => {
          const elevation = rowData["Elevation"];
          return elevation != null ? elevation.toFixed(2) : '';
        }}
      />
      <Column
        header={"Actions"}
        body={(rowData) => {
          return (
            <div className={"mr-1"}>
              <Button
                className={"small-button"}
                tooltip={"Go to Well Detail Page"}
                icon={"pi pi-eye"}
                onClick={(e) => {
                  window.open(`/wells?pointid=${rowData.PointID}`, "_blank");
                }}
              />
            </div>
          );
        }}
      />
    </DataTable>
  );
}
// ============= EOF =============================================
