import { downloadCSV } from "../../../components/download_util.js";
import { retrieveItems } from "../../../util.js";

export const downloadPVACDData = async (selectedLocations, plotData) => {
    if (!selectedLocations || selectedLocations.length === 0) {
        console.warn("No selected locations to download");
        return;
      }
        const sitesWithoutData = selectedLocations.filter((selectedSite) => {
            const siteName = selectedSite.properties.name;
            return !plotData.some((data) => data.name.includes(siteName));
        });

        if (sitesWithoutData.length > 0) {
            const fetchMissingData = async (sites) => {
                const newPlotData = [];

                for (const item of sites) {
                    const url = item.properties["selflink"] + "?$expand=Things/Datastreams";
                    const response = await fetch(url);
                    const data = await response.json();
                    const datastreams = data.Things.map((t) => t.Datastreams).flat();

                    const observationsPromises = datastreams.map(async (ds) => {
                        const observations = await retrieveItems(
                            ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc", []
                        );

                        return {
                            mode: ds.name.includes("Manual") ? "markers" : "lines",
                            x: observations.map((s) => s.phenomenonTime),
                            y: observations.map((s) => s.result),
                            name: `${item.properties.name} (${ds.name.includes("Manual") ? "Manual" : "Continuous"})`,
                        };
                    });

                    const observations = await Promise.all(observationsPromises);
                    newPlotData.push(...observations);
                }
                return newPlotData;
            };

            const newPlotData = await fetchMissingData(sitesWithoutData);
            // Combine old and new plot data for selected sites that didn't have data
            plotData = [...plotData, ...newPlotData];
        }
    
        //Loop for each site so each site has its own CSV
      selectedLocations.forEach((selectedSite) => {
        const siteName = selectedSite.properties.name;
    
        const sitePlotData = plotData.filter((data) => data.name.includes(siteName));
    
        const manualData = sitePlotData.filter((data) => data.name.includes("Manual"));
        const continuousData = sitePlotData.filter((data) => !data.name.includes("Manual"));
    
        const dataMap = {};
    
        manualData.forEach((data) => {
            data.x.forEach((date, index) => {
                const dateObj = new Date(date);
                let formattedDate = dateObj.toISOString();

                // Check if the time is 00:00:00.000Z, which means no specific time was set
                if (dateObj.getUTCHours() === 0 && dateObj.getUTCMinutes() === 0 && dateObj.getUTCSeconds() === 0) {
                    // If time is not set, default to "00:00:00Z"
                    formattedDate = dateObj.toISOString().split('T')[0] + 'T00:00:00.000Z';
                }

                if (!dataMap[formattedDate]) {
                    dataMap[formattedDate] = { manual: "", continuous: "" };
                }
                dataMap[formattedDate].manual = data.y[index] !== null ? data.y[index].toFixed(2) : "";
            });
        });
    
        continuousData.forEach((data) => {
          data.x.forEach((date, index) => {
            const formattedDate = new Date(date).toISOString();
            if (!dataMap[formattedDate]) {
              dataMap[formattedDate] = { manual: "", continuous: "" };
            }
            dataMap[formattedDate].continuous = data.y[index] !== null ? data.y[index].toFixed(2) : "";
          });
        });
    
        const mergedData = Object.entries(dataMap).map(([date, values]) => ({
          date,
          manual: values.manual || "",
          continuous: values.continuous || "",
        }));
    
        // Sort the data by date/time
        mergedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    
        const headers = [
          { label: "Date", key: "date" },
          { label: "Manual (ft bgs)", key: "manual" },
          { label: "Continuous (ft bgs)", key: "continuous" },
        ];
    
        // Download the CSV
        downloadCSV(`${siteName}_observations`, mergedData, headers);
      });
};