// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Column } from "primereact/column";
import { boldBodyTemplateGen } from "./KeyValueTable";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../../util";
import { useFiefTokenInfo } from "@fief/fief/react";

export default function EquipmentTable({ pointId }) {
  const [equipment, setEquipment] = useState([]);
  const tokenInfo = useFiefTokenInfo();

  useEffect(() => {
    //console.log("EquipmentTable useEffect pointId", pointId);
    if (pointId === undefined) {
      setEquipment([]);
    }
    nmbgmr_getJson(
      `authorized/locations/equipment?pointid=${pointId}`,
      tokenInfo?.access_token,
    ).then((data) => {
      //console.debug("equipment", data);
      setEquipment(data);
    });
  }, [pointId]);

  return (
    <div>
      <DataTable
        className={"compact-table"}
        stripedRows
        size={"small"}
        value={equipment}
      >
        <Column
          field={"EquipmentType"}
          header={"Type"}
          body={boldBodyTemplateGen("EquipmentType")}
        />
        <Column field={"Model"} header={"Model"} />
        <Column field={"SerialNo"} header={"Serial No."} />
        <Column field={"DateInstalled"} header={"Install Date"} />
        <Column field={"DateRemoved"} header={"Removal Date"} />
      </DataTable>
    </div>
  );
}
// ============= EOF =============================================
