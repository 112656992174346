import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

const GWDataTable = ({
  enhancedLocations,
  selectedLocations,
  setSelectedLocations,
  onSelectionChange,
  handleDownload,
  downloadLoading,
  toggleDataTable,
  setHydrographSelectedSite,
  setActiveTabIndex,
  plot_hygrogaphs,
  setMultiHydrographSites,
  setShowMultiHydrograph,
}) => {
  return (
    <Card
      title="Groundwater Summary Table"
      subTitle={
        <>
          Launch the hydrograph / site info, or select locations to download data.
          <br />
          Only PVACD sites populate the table at this time.
        </>
      }
    >
      {/* Data Table */}
      <DataTable
        size="small"
        value={enhancedLocations}
        selection={selectedLocations}
        dataKey="id"
        onSelectionChange={onSelectionChange}
        stripedRows
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column sortable field={"properties.name"} header={"Name"}></Column>
        <Column
          header="Actions"
          body={(item) => (
            <div className="flex">
              <Button
                severity="warning"
                style={{ marginRight: "0.2rem" }}
                rounded
                icon="pi pi-chart-line"
                tooltip="View Hydrograph"
                onClick={() => {
                  setHydrographSelectedSite([item]);
                  setActiveTabIndex(1);
                  toggleDataTable();
                  setSelectedLocations([item]);
                }}
              />
              <Button
                severity="info"
                rounded
                outlined
                icon="pi pi-arrow-up-right"
                tooltip="More information"
                onClick={() => {
                  setHydrographSelectedSite([item]);
                  setActiveTabIndex(0);
                  toggleDataTable();
                  setSelectedLocations([item]);
                }}
              />
            </div>
          )}
        />
            <Column
              header="Latest Manual Water Level (ft bgs)"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const manualObs = item.latestObservations.find(
                  (obs) => obs && obs.datastreamName.includes("Manual")
                );
                return manualObs && manualObs.observation
                  ? manualObs.observation.result.toFixed(2)
                  : "N/A";
              }}
            />
            <Column
              header = "Latest Manual Measurement Date"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const manualObs = item.latestObservations.find(
                  (obs) => obs && obs.datastreamName.includes("Manual")
                );
                return manualObs && manualObs.observation
                  ? new Date(manualObs.observation.phenomenonTime).toLocaleDateString()
                  : "N/A";
              }}
            >
            </Column>
            <Column
              header="Latest Continuous Water Level (ft bgs)"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const continuousObs = item.latestObservations.find(
                  (obs) => obs && !obs.datastreamName.includes("Manual")
                );
                return continuousObs && continuousObs.observation
                  ? continuousObs.observation.result.toFixed(2)
                  : "N/A";
              }}
            />
            <Column
              header="Latest Continuous Measurement Date"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const continuousObs = item.latestObservations.find(
                  (obs) => obs && !obs.datastreamName.includes("Manual")
                );
                return continuousObs && continuousObs.observation
                  ? new Date(continuousObs.observation.phenomenonTime).toLocaleDateString()
                  : "N/A";
              }}
              >
              </Column>
      </DataTable>

      {/* Action buttons for selected sites */}
      <Divider />
      {(!selectedLocations || selectedLocations.length === 0) && (
        <>
          <Button
            disabled={true}
            rounded
            label="Download Data"
            icon="pi pi-download"
            severity="warning"
          />
          <Button
            className="ml-2"
            disabled={true}
            rounded
            label="Plot Multiple Sites"
            icon="pi pi-chart-line"
            severity="warning"
          />
        </>
      )}

      {selectedLocations && selectedLocations.length === 1 && (
        <>
          <Divider />
          <Button
            rounded
            label="Download Data"
            icon="pi pi-download"
            severity="warning"
            tooltip="Download CSV Data for Selected Location(s)"
            loading={downloadLoading}
            onClick={handleDownload}
          />
          <Button
            className="ml-2"
            disabled={true}
            rounded
            label="Plot Multiple Sites"
            icon="pi pi-chart-line"
            severity="warning"
          />
        </>
      )}

      {selectedLocations && selectedLocations.length >= 2 && (
        <>
          <Divider />
          <Button
            rounded
            label="Download Data"
            icon="pi pi-download"
            severity="warning"
            tooltip="Download CSV Data for Selected Location(s)"
            loading={downloadLoading}
            onClick={handleDownload}
          />
          <Button
            className="ml-2"
            rounded
            label="Plot Multiple Sites"
            icon="pi pi-chart-line"
            severity="warning"
            tooltip="Plot Multi-site Hydrograph"
            onClick={() => {
              setMultiHydrographSites(selectedLocations);
              plot_hygrogaphs(selectedLocations, 'multi');
              setShowMultiHydrograph(true);
            }}
          />
        </>
      )}
    </Card>
  );
};

export default GWDataTable;
