import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import ExtNavDialog from '../../../components/ExtNavDialog';

const LayerControl = ({ 
    layers, 
    layerVisibility, 
    handleLayerSelection,
    openOnLoad 
  }) => {
  const op = useRef(null);
  const buttonRef = useRef(null);

  const [showTrendsLegend, setShowTrendsLegend] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(null);

  const onLayerChange = (e) => {
    const { name: layerId, checked } = e.target;

    if (layerId === "weaver:gw:nmbgmr:trends" && checked) {
      layers.forEach((layer) => {
        if (layer.id !== "weaver:gw:nmbgmr:trends") {
          handleLayerSelection(layer.id, false);
        }
      });
    } else if (layerId !== "weaver:gw:nmbgmr:trends" && checked) {
      handleLayerSelection("weaver:gw:nmbgmr:trends", false);
    }

    handleLayerSelection(layerId, checked);
  };

  useEffect(() => {
    if (layerVisibility["weaver:gw:nmbgmr:trends"]) {
      //console.log("showing trends legend");
      setShowTrendsLegend(true);
    } else {
      //console.log("hiding trends legend");
      setShowTrendsLegend(false);
    }
  }, [layerVisibility]);

  //Show the overlay panel on load, if openOnLoad is true
  // useEffect(() => {
  //   if (openOnLoad) {
  //     setTimeout(() => {
  //       op.current.show(null, buttonRef.current);
  //     }, 0);
  //   }
  // }, [openOnLoad]);

  return (
    <div className="layer-control">
      <Button
        ref={buttonRef}
        label="Data Layers"
        onClick={(e) => op.current.toggle(e)}
        icon="pi pi-database"
        aria-haspopup
        aria-controls="overlay_panel"
        tooltip="Select Data Sources"
        tooltipOptions={{ position: 'right' }}
        className="p-button-info p-button-raised"
      />
      <ExtNavDialog 
        visible={currentLayer !== null} 
        onHide={() => setCurrentLayer(null)} 
        onConfirm={() => window.open(currentLayer?.homeLink, '_blank')}
        siteInfo={currentLayer?.longLabel}
      />
      <OverlayPanel ref={op} id="overlay_panel" dismissable style={{ width: "25rem" }}>
        {layers.map((layer) => (
          <React.Fragment key={layer.id}>
            <div
              className="field-checkbox grid"
              style={{
                marginLeft: layer.parentId ? '1.5rem' : '0', 
                fontSize: layer.parentId ? '0.9em' : '1em', 
              }}
            >
              <div className="flex align-items-center mb-2">
                <Checkbox
                  className="mr-2"
                  inputId={layer.id}
                  name={layer.id}
                  checked={layerVisibility[layer.id]}
                  onChange={onLayerChange}
                />
                <label htmlFor={layer.id} className="mr-2 font-bold">
                  {layer.label}
                </label>

                {layer.paint && layer.paint["circle-color"] && (
                  <div
                    style={{
                      backgroundColor: Array.isArray(layer.paint["circle-color"])
                        ? layer.paint["circle-color"][3]
                        : layer.paint["circle-color"],
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%',
                      border: '1px solid #000',
                    }}
                  />
                )}
              </div>
              
              {!layer.parentId && (
                <div className="col-12 pt-1">
                  <small>
                    <Button
                      label={layer.longLabel}
                      tooltip="Visit the Data Source"
                      className="p-button-text p-button-sm p-button-info p-button-text p-button-rounded m-0 p-0 pt-1"
                      onClick={() => setCurrentLayer(layer)}
                    />
                  </small>
                </div>
              )}
              {!layer.parentId && (
                <div className="col-12 pt-0">
                  <small>{layer.subTitle}</small>
                </div>
              )}
              {layer.parentId && (
                <div className="col-12 pt-0">
                  <small>
                  <Button 
                      label="How trends are calculated" 
                      className="p-button-text p-button-sm p-buton-error p-button-text p-button-rounded m-1 p-1 pt-1"
                      tooltip="Trends are calculated using regression analysis on monitoring well measurements from NMBGMR where there are more than 4 observations over 2 years, or at least 10 total observations. Wells with with a slope of 0.25 were considered to be “Increasing”. Wells with less than -0.25 were considered to be “Decreasing”. Others are considered to be “Stable”."
                    />
                  </small>
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
        <hr />
        <div className="text-sm align-items-center text-center">
          More sources coming soon!
        </div>
      </OverlayPanel>
      
      {showTrendsLegend && (
        <div 
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '20px',
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1000
          }}
        >
          <h3 className='m-0 p-0'>NMBGMR Trends Legend:</h3>
          <p>
            <span
              style={{
                backgroundColor: "#d7e1fc",
                padding: "2px 10px",
                marginRight: "5px",
              }}
            ></span>
            Stable
          </p>
          <p>
            <span
              style={{
                backgroundColor: "#0042f7",
                padding: "2px 10px",
                marginRight: "5px",
              }}
            ></span>
            Increasing (less depth to water) &uarr;
          </p>
          <p>
            <span
              style={{
                backgroundColor: "#fa6902",
                padding: "2px 10px",
                marginRight: "5px",
              }}
            ></span>
            Decreasing (more depth to water) &darr;
          </p>
        </div>
      )}
    </div>
  );
};

export default LayerControl;