import { useEffect } from 'react';

export function useSessionToast(toastRef, key, toastOptions) {
  useEffect(() => {
    try {
      const toastShown = sessionStorage.getItem(key);

      if (!toastShown && toastRef.current) {
        toastRef.current.show(toastOptions);
        sessionStorage.setItem(key, true);
      }
    } catch (error) {
      console.error('Session storage is not available:', error);
    }
  }, [key, toastOptions, toastRef]);
}