// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../../util";
import { useFiefTokenInfo } from "@fief/fief/react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { downloadCSV } from "../download_util";
import EquipmentTable from "../LocationDetail/EquipmentTable";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import ManualMeasurementsTable from "./ManualMeasurementTable";
import { ProgressSpinner } from "primereact/progressspinner";

export default function DataView() {
  const [pointId, setPointId] = useState(undefined);
  return (
    <div className={"grid col-10 col-offset-1 mt-3"}>
      <Card title={"Locations"} className={"mb-3"}>
        <LocationTable setPointId={setPointId} />
      </Card>
      <Card title={"Equipment"} className={"mb-3"}>
        <EquipmentTable pointId={pointId} />
      </Card>
      <Card title={"Manual Measurements"} className={"mb-3"}>
        <ManualMeasurementsTable pointId={pointId} />
      </Card>
    </div>
  );
}
//
// function WellTable({ pointId }) {
//   const tokenInfo = useFiefTokenInfo();
//   const [rows, setRows] = useState([]);
//
//   const auth_api_getJson = (url) => {
//     return nmbgmr_getJson(url, tokenInfo?.access_token);
//   };
//
//   useEffect(() => {
//     if (pointId === undefined) {
//       return;
//     }
//
//     auth_api_getJson("public/tabular/wells?pointid").then((data) => {
//       console.log(data);
//       setRows(data.items);
//     });
//   }, [pointId]);
//
//   return (
//     <div>
//       {/*<Button onClick={}>Download</Button>*/}
//       <DataTable className={"compact-table"} size={"small"} value={rows}>
//         <Column filter field="WellID" header="WellID" />
//       </DataTable>
//     </div>
//   );
// }

function LocationTable({ setPointId }) {
  const tokenInfo = useFiefTokenInfo();
  const auth_api_getJson = (url) => {
    return nmbgmr_getJson(url, tokenInfo?.access_token);
  };

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      PointID: { value: "", matchMode: "contains" },
      //   'country.name': { value: '', matchMode: 'contains' },
      //   'company': { value: '', matchMode: 'contains' },
      //   'representative.name': { value: '', matchMode: 'contains' },
    },
  });

  const handleDownload = () => {
    const df = rows.map((row) => {
      return [row.PointID, row.PublicRelease, row.AlternateSiteID];
    });
    downloadCSV("locations.csv", df, [
      "PointID",
      "PublicRelease",
      "AlternateSiteID",
    ]);
  };

  useEffect(() => {
    console.log("useEffect", lazyParams);

    let url = "authorized/tabular/welllocations?";
    if (
      lazyParams.filters.PointID.value !== "" &&
      lazyParams.filters.PointID.value !== null
    ) {
      let pointId = lazyParams.filters.PointID.value;
      if (pointId.length <= 3) {
        return;
      }
      url += `pointid=${pointId}&`;
    }
    if (lazyParams.first !== 0) {
      url += `page=${lazyParams.page}`;
    }
    // url += `page=${lazyParams.page + 1}`;
    url = `${url}&pointid_comparator=${lazyParams.filters.PointID.matchMode}`;
    setLoading(true);
    auth_api_getJson(url)
      // auth_api_getJson(`public/tabular/locations`)
      .then((data) => {
        let items = [];
        let total = 0;
        if (data !== undefined) {
          items = data.items;
          total = data.total;
        }
        console.log("data", data);
        setRows(items);
        setTotalRecords(total);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [lazyParams]);

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSelectionChange = (e) => {
    if (e.value === null) {
      return;
    }
    setPointId(e.value.PointID);
    setSelectedLocation(e.value);
  };
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  return (
    <div>
      <Button onClick={handleDownload}>Download</Button>
      <DataTable
        className={"compact-table"}
        size={"small"}
        value={rows}
        lazy
        stripedRows
        totalRecords={totalRecords}
        first={lazyParams.first}
        filters={lazyParams.filters}
        onPage={onPage}
        onFilter={onFilter}
        paginator
        rows={10}
        filterDisplay="row"
        loading={loading}
        loadingIcon={<ProgressSpinner />}
        onSelectionChange={onSelectionChange}
        selection={selectedLocation}
        editMode={"row"}
        rowClassName={(rowData) => {
          return rowData.PublicRelease === "false"
            ? "bg-red-200"
            : "bg-green-200";
        }}
      >
        <Column selectionMode="single" headerStyle={{ width: "1em" }}></Column>
        <Column filter field="PointID" header="PointID" />
        <Column
          field={"PublicRelease"}
          header={"Public"}
          body={(rowData) => {
            return <div>{rowData.PublicRelease ? "Yes" : "No"}</div>;
          }}
        />
        <Column field="SiteID" header={"Site ID"} />
        <Column field="AlternateSiteID" header={"Alternate SiteID"} />
        <Column field="Elevation" header={"Elevation"} />
        <Column field="well.hole_depth_ftbgs" header={"Hole Depth(ft)"} />
        <Column field="well.well_depth_ftbgs" header={"Well Depth(ft)"} />
        <Column field="well.casing_depth_ftbgs" header={"Casing Depth(ft)"} />
        <Column field="well.formation" header={"Formation"} />
        <Column field="well.measuring_point" header={"Measuring Point"} />
        <Column
          field="well.measuring_point_height_ftin"
          header={"Measuring Point Height"}
        />
        <Column
          field="SiteNames"
          editor={(options) => textEditor(options)}
          header={"Site Names"}
        />
        {/*<Column*/}
        {/*  rowEditor*/}
        {/*  headerStyle={{ width: "10%", minWidth: "1rem" }}*/}
        {/*  bodyStyle={{ textAlign: "center" }}*/}
        {/*/>*/}
      </DataTable>
    </div>
  );
}
// ============= EOF =============================================
