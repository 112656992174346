import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useRef } from "react";

const GWPointsListDialog = ({ visible, onHide, points, onSelectPoint }) => {

  const toast = useRef(null);
  const handleRowClick = (rowData) => {
    onSelectPoint(rowData);
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <Button
        label={rowData.properties.name}
        className="p-button-link"
        onClick={() => handleRowClick(rowData)}
      />
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog 
        visible={visible} 
        onHide={onHide}
        style={{ width: "60vw" }} 
        header="Multiple sites at location - select one:">
        <DataTable value={points} size="small" stripedRows>
          <Column field="properties.name" header="Name" body={nameBodyTemplate}></Column>
          <Column field="properties.elevation_ft" header="Elevation (ft)"></Column>
          <Column field="properties.well_depth.value" header="Well Depth (ft)"></Column>
          <Column field="properties.hole_depth.value" header="Hole Depth (ft)"></Column>
          <Column field="properties.ose_well_id" header="OSE Well ID"></Column>
          <Column field="properties.site_id" header="Site ID"></Column>
          <Column field="properties.alternate_site_id" header="Alternate Site ID"></Column>
        </DataTable>
      </Dialog>
    </>
  );
};

export default GWPointsListDialog;