// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Card } from "primereact/card";
import GWMap from "./GWMap"
import GWDataTable from "./GWDataTable";
import GWPointsListDialog from "./GWPointsListDialog";
import SiteInfoDialog from "./PVACDComponents/PVACDSiteInfoDialog";
import NmbgmrSiteInfoDialog from "./NMBGMRComponents/NMBGMRSiteInfoDialog";
//import LayerControl from "./LayerControl";
//import MapComponent from "../../Map/MapComponent";
import { nmbgmr_gw_locations, pvacd_gw_locations, usgs_nwis_gw_locations, bernco_gw_locations, ebid_gw_locations, trends } from "../Groundwater/Sources";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useSessionToast } from "../../../hooks/useSessionToast";
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { mToFt, retrieveItems } from "../../../util";
import Plot from "react-plotly.js";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import { isTouchDevice } from "../../../util";
import { downloadPVACDData } from "./PVACDDownload";
import NavDialog from "../Groundwater/NavDialog";
import GWSelectedDataTable from "./GWSelectedDataTable";
import BERNCOSiteInfoDialog from "./BERNCOComponents/BERNCOSiteInfoDialog";
import EBIDSiteInfoDialog from "./EBIDComponents/EBIDSiteInfoDialog";

const sources = [
  nmbgmr_gw_locations,
  { ...trends, parentId: "weaver:gw:nmbgmr" },
  usgs_nwis_gw_locations,
  pvacd_gw_locations,
  bernco_gw_locations,
  ebid_gw_locations,
  ];

export default function GroundwaterDashboard_2() {
  const [sourceData, setSourceData] = useState({});

  const toastRef = useRef();
  const sessionToast = useRef(null)

  //PVACD Locations
  const [pvacdlocations, setpvacdLocations] = useState([]);
  //PVACD dialog display logic
  const [hydrographSelectedSite, setHydrographSelectedSite] = useState(null);
  const [showHydrograph, setShowHydrograph] = useState(false);

  //NM Bureau of Geology Locations
  const [nmbgmrLocations, setNmbgmrLocations] = useState([]);
  //NM Bureau of Geology dialog display logic
  const [nmbgmrSelectedSite, setNmbgmrSelectedSite] = useState(null);
  const [showNmbgmrDialog, setShowNmbgmrDialog] = useState(false);

  //NMBGMR Trends Locations
  const [trendsLocations, setTrendsLocations] = useState([]);

  //BernCo Locations
  const [berncoLocations, setBerncoLocations] = useState([]);
  const [berncoSelectedSite, setBerncoSelectedSite] = useState(null);
  const [showBerncoDialog, setShowBerncoDialog] = useState(false);
  const [berncoPlotData, setBerncoPlotData] = useState([]);
  const [berncoLoading, setBerncoLoading] = useState(false);

  //EBID Locations
  const [ebidLocations, setEbidLocations] = useState([]);
  const [ebidSelectedSite, setEbidSelectedSite] = useState(null);
  const [showEbidDialog, setShowEbidDialog] = useState(false);
  const [ebidPlotData, setEbidPlotData] = useState([]);
  const [ebidLoading, setEbidLoading] = useState(false);

  const [enhancedLocations, setEnhancedLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();

  //selected features from polygon selection (or site selection eventually)
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  //callback to receive selected features from polygon selection
  const handleSelectedFeatures = (features) => {
    setSelectedFeatures(features);
  }

  //points list dialog for multi site click
  const [showPointsListDialog, setShowPointsListDialog] = useState(false);
  const [pointsAtClick, setPointsAtClick] = useState([]);

  //Multi site hydrograph logic
  const [showMultiHydrograph, setShowMultiHydrograph] = useState(false);
  const [multiHydrographSites, setMultiHydrographSites] = useState([]);
  const [multiPlotData, setMultiPlotData] = useState([]);
  const [multiLoading, setMultiLoading] = useState(false);

  const [popupContent, setPopupContent] = useState(undefined);
  //const [stickyPopup, setStickyPopup] = useState(false);

  //Data table display logic
  const [showDataTable, setShowDataTable] = useState(false);
  const toggleDataTable = () => {
    setShowDataTable((prev) => !prev);
  };

  //active tabe display logic
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  //download loading state logic
  const [downloadLoading, setDownloadLoading] = useState(false);

  //Nav dialog display logic
  const [showNavDialog, setShowNavDialog] = useState(false);
  const [sitenoNav, setSitenoNav] = useState('');
  const [usgsLink, setUsgsLink] = useState('');
  const handleNavDialog = () => {
    window.open(usgsLink, "_blank");
    setShowNavDialog(false);
  }


  const [layout, setLayout] = useState({
    autosize: true,
    plot_bgcolor: "",
    paper_bgcolor: "",
    margin: { t: 50, b: 100, l: 50, r: 50, pad: 4 },
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });

  //----------------- Create array of PVACD sites -----------------
  useEffect(() => {
    //console.log("sourceData", sourceData);
    if (!sourceData["weaver:gw:pvacd"]) return;
    //console.log("sourceData", sourceData["weaver:gw:pvacd"].features);
    setpvacdLocations(sourceData["weaver:gw:pvacd"].features);
  }, [sourceData]);

  //----------------- Fetch latest observations for each PVACD location on page load-----------------
  useEffect(() => {
    if (pvacdlocations.length === 0) return;

    const fetchLatestObservations = async () => {
      const updatedLocations = await Promise.all(
        pvacdlocations.map(async (location) => {
          const url = location.properties["selflink"] + "?$expand=Things/Datastreams";
          try {
            const response = await fetch(url);
            const data = await response.json();
            const datastreams = data.Things.map((t) => t.Datastreams).flat();

            const observationPromises = datastreams.map(async (ds) => {
              const obsUrl =
                ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc&$top=1";
              try {
                const obsResponse = await fetch(obsUrl);
                const obsData = await obsResponse.json();
                const observation = obsData.value[0];
                // console.log("observation", observation);
                return {
                  datastreamName: ds.name,
                  observation: observation,
                };
              } catch (error) {
                console.error("Error fetching observation", error);
                return null;
              }
            });

            const observations = await Promise.all(observationPromises);
            return {
              ...location,
              //id used for selection to map feature matching
              id: location.id,
              latestObservations: observations,
            };
          } catch (error) {
            console.error("Error fetching datastreams", error);
            return location;
          }
        })
      );

      setEnhancedLocations(updatedLocations);
    };

    fetchLatestObservations();
  }, [pvacdlocations]);

  //----------------- Create array of NM Bureau of Geology sites -----------------
  useEffect(() => {
    if (!sourceData["weaver:gw:nmbgmr"]) return;
    setNmbgmrLocations(sourceData["weaver:gw:nmbgmr"].features);
  }, [sourceData]);

  //----------------- Create array of NMBGMR Trends sites -----------------
  useEffect(() => {
    if (!sourceData["weaver:gw:nmbgmr:trends"]) return;
    setTrendsLocations(sourceData["weaver:gw:nmbgmr:trends"].features);
  }, [sourceData]);

  //----------------- Create array of BernCo sites -----------------
  useEffect(() => {
    if (!sourceData["weaver:gw:bernco"]) return;
    setBerncoLocations(sourceData["weaver:gw:bernco"].features);
  }, [sourceData]);

  //----------------- Create array of EBID sites -----------------
  useEffect(() => {
    if (!sourceData["weaver:gw:ebid"]) return;
    setEbidLocations(sourceData["weaver:gw:ebid"].features);
  }, [sourceData]);

  // ----------------- Fetch BernCo site data -----------------
  const fetchBerncoSiteData = async (site) => {
    const url = site.properties["selflink"] + "?$expand=Things/Datastreams";
    try {
      const response = await fetch(url);
      const data = await response.json();
      const datastreams = data.Things.map((t) => t.Datastreams).flat();
  
      const observationPromises = datastreams.map(async (ds) => {
        const obsUrl =
          ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc&$top=1";
        try {
          const obsResponse = await fetch(obsUrl);
          const obsData = await obsResponse.json();
          const observation = obsData.value[0];
          //console.log("bernco observation", observation);
          return {
            datastreamName: ds.name,
            observation: observation,
          };
        } catch (error) {
          console.error("Error fetching observation", error);
          return null;
        }
      });
  
      const observations = await Promise.all(observationPromises);
      const updatedSite = {
        ...site,
        latestObservations: observations,
      };
      //console.log("updatedSite", updatedSite);
      setBerncoSelectedSite(updatedSite);
      setShowBerncoDialog(true);
      plotBerncoHydrograph([updatedSite]);
  
    } catch (error) {
      console.error("Error fetching datastreams", error);
    }
  };

  // ----------------- Fetch EBID site data -----------------
  const fetchEbidSiteData = async (site) => {
    const url = site.properties["selflink"] + "?$expand=Things/Datastreams";
    try {
      const response = await fetch(url);
      const data = await response.json();
      const datastreams = data.Things.map((t) => t.Datastreams).flat();
  
      const observationPromises = datastreams.map(async (ds) => {
        const obsUrl =
          ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc&$top=1";
        try {
          const obsResponse = await fetch(obsUrl);
          const obsData = await obsResponse.json();
          const observation = obsData.value[0];
          //console.log("bernco observation", observation);
          return {
            datastreamName: ds.name,
            observation: observation,
          };
        } catch (error) {
          console.error("Error fetching observation", error);
          return null;
        }
      });
  
      const observations = await Promise.all(observationPromises);
      const updatedSite = {
        ...site,
        latestObservations: observations,
      };
      //console.log("updatedSite", updatedSite);
      setEbidSelectedSite(updatedSite);
      setShowEbidDialog(true);
      plotEbidHydrograph([updatedSite]);

    } catch (error) {
      console.error("Error fetching datastreams", error);
    }
  };


  // ----------------- Plot BernCo hydrograph -----------------
  const plotBerncoHydrograph = (items) => {
    setBerncoLoading(true);
    const manualColor = '#FF7F0F'; 
    const continuousColor = '#1F77B4';

    let promises = items.map((item) => {
      const url = item.properties["selflink"] + "?$expand=Things/Datastreams";
      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let datastreams = data.Things.map((t) => t.Datastreams).flat();
          let dspromise = datastreams.map((ds) => {
            return retrieveItems(
              ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc",
              [],
            ).then((observations) => {
              const isManual = ds.name.includes("Manual");
              const color = isManual ? manualColor : continuousColor;
              return {
                mode: ds.name.includes("Manual") ? "markers" : "lines",
                x: observations.map((s) => s.phenomenonTime),
                y: observations.map((s) => s.result),
                name: `${item.properties.name} (${ds.name.includes("Manual") ? "Manual" : "Continuous"})`,
                line: { color: color },
                marker: { color: color}, 
              };
            });
          });
          return Promise.all(dspromise).then((data) => {
            //console.log("bernco hydrograph data", data);
            return data;
          });
        });
    });
  
    Promise.all(promises)
      .then((data) => {
        setBerncoPlotData(data.flat());
        //console.log("bernco plot data", data.flat());
      })
      .finally(() => {
        setBerncoLoading(false);
      });
  };

  // ----------------- Plot EBID hydrograph -----------------
  const plotEbidHydrograph = (items) => {
    setEbidLoading(true);
    const manualColor = '#FF7F0F'; 
    const continuousColor = '#1F77B4';

    let promises = items.map((item) => {
      const url = item.properties["selflink"] + "?$expand=Things/Datastreams";
      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let datastreams = data.Things.map((t) => t.Datastreams).flat();
          let dspromise = datastreams.map((ds) => {
            return retrieveItems(
              ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc",
              [],
            ).then((observations) => {
              const isManual = ds.name.includes("Manual");
              const color = isManual ? manualColor : continuousColor;
              return {
                mode: ds.name.includes("Manual") ? "markers" : "lines",
                x: observations.map((s) => s.phenomenonTime),
                y: observations.map((s) => s.result),
                name: `${item.properties.name} (${ds.name.includes("Manual") ? "Manual" : "Continuous"})`,
                line: { color: color },
                marker: { color: color}, 
              };
            });
          });
          return Promise.all(dspromise).then((data) => {
            //console.log("ebid hydrograph data", data);
            return data;
          });
        });
    });
  
    Promise.all(promises)
      .then((data) => {
        setEbidPlotData(data.flat());
        //console.log("ebid plot data", data.flat());
      })
      .finally(() => {
        setEbidLoading(false);
      });
  };
  
  // ----------------- Plot hydrographs useEffectfor selected site -----------------
  useEffect(() => {
    if (hydrographSelectedSite && hydrographSelectedSite.length > 0) {
      plot_hygrogaphs(hydrographSelectedSite, 'single');
      setShowHydrograph(true);
    } else {
      setShowHydrograph(false);
    }
  }, [hydrographSelectedSite]);

  //----------------- Map click event handler -----------------
  const onMapClick = (event, features) => {
    if (features && features.length > 0) {
      if (features.length === 1) {
        const clickedFeature = features[0];
        handleFeatureClick(clickedFeature);
      } else {
        setPointsAtClick(features);
        setShowPointsListDialog(true);
      }
    }
  };

  //----------------- Handle feature click -----------------
  const handleFeatureClick = (clickedFeature) => {
    if (clickedFeature.layer.source === "weaver:gw:usgs") {
      setSitenoNav(clickedFeature.properties.site_no);
      setUsgsLink("https://waterdata.usgs.gov/monitoring-location/" + clickedFeature.properties.site_no);
      setShowNavDialog(true);
      return;
    }
  
    let clickedSite;
  
    if (clickedFeature.layer.source === "weaver:gw:pvacd") {
      clickedSite = enhancedLocations.find((site) => site.id === clickedFeature.id);
      if (clickedSite) {
        setHydrographSelectedSite([clickedSite]);
        setSelectedLocations([clickedSite]);
        setShowHydrograph(true);
      }
    }
  
    if (clickedFeature.layer.source === "weaver:gw:nmbgmr") {
      clickedSite = nmbgmrLocations.find((site) => site.id === clickedFeature.id);
      if (clickedSite) {
        setNmbgmrSelectedSite(clickedSite);
        setShowNmbgmrDialog(true);
      }
    }

    if (clickedFeature.layer.source === "weaver:gw:nmbgmr:trends") {
      clickedSite = nmbgmrLocations.find((site) => site.id === clickedFeature.id);
      if (clickedSite) {
        setNmbgmrSelectedSite(clickedSite);
        setShowNmbgmrDialog(true);
      }
    }


    if (clickedFeature.layer.source === "weaver:gw:bernco") {
      clickedSite = berncoLocations.find((site) => site.id === clickedFeature.id);
      if (clickedSite) {
        fetchBerncoSiteData(clickedSite);
      }
    }

    if (clickedFeature.layer.source === "weaver:gw:ebid") {
      clickedSite = ebidLocations.find((site) => site.id === clickedFeature.id);
      if (clickedSite) {
        fetchEbidSiteData(clickedSite);
      }
    }
  
    setPopupContent({
      coordinates: clickedFeature.geometry.coordinates,
      children: (
        <h2>
          <strong style={{ color: "black " }}>{clickedFeature.properties.name}</strong>
        </h2>
      ),
    });
  };

  // ----------------- Handle Point Selection from List -----------------
  const handlePointSelection = (selectedFeature) => {
    setShowPointsListDialog(false);
    handleFeatureClick(selectedFeature);
  };

  
  // ----------------- Fetch hydrograph data for selected PVACD sites from ST endpoint -----------------
  // Data cache for hydrograph data
  const dataCache = useRef({});

  const plot_hygrogaphs = (items, type = 'single') => {
    let promises = items.map((item) => {
      const itemId = item.id;
      if (dataCache.current[itemId]) {
        return Promise.resolve(dataCache.current[itemId]);
      }
      else {
        let url = item.properties["selflink"] + "?$expand=Things/Datastreams";
        return fetch(url)
          .then((response) => response.json())
          .then((data) => {
            let datastreams = data.Things.map((t) => t.Datastreams).flat();
            let dspromise = datastreams.map((ds) => {
              return retrieveItems(
                ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc",
                [],
              ).then((observations) => {
                return {
                  mode: ds.name.includes("Manual") ? "markers" : "lines",
                  x: observations.map((s) => s.phenomenonTime),
                  y: observations.map((s) => s.result),
                  name: `${item.properties.name} (${ds.name.includes("Manual") ? "Manual" : "Continuous"})`, 
                };
              });
            });
            return Promise.all(dspromise).then((data) => {
              dataCache.current[itemId] = data;
              //console.log("pvacd hydrograph data", data);
              return data;
            });
          });
      }
    });
  

    if (type === 'single') {
      setLoading(true);
      Promise.all(promises)
        .then((data) => {
          setPlotData(data.flat());
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'multi') {
      setMultiLoading(true);
      Promise.all(promises)
        .then((data) => {
          setMultiPlotData(data.flat());
        })
        .finally(() => {
          setMultiLoading(false);
        });
    }
  };

  // ----------------- Download data for selected sites -----------------
  const handleDownload = () => {
    setDownloadLoading(true);
    downloadPVACDData(selectedLocations, plotData).finally(() => {
    setDownloadLoading(false);
    });
  };

  // ----------------- Sidebar settings -----------------
  const sidebarWidth = isTouchDevice() ? "100vw" : "50vw";

  // ----------------- Session Toast -----------------
  useSessionToast(sessionToast, "groundwater-toast", {
    severity: "info",
    summary: "Welcome to the improved Groundwater Dashboard!",
    detail: (
      <>
      We’re working hard to create a fantastic new experience for you. This tool allows users to visualize and analyze groundwater data from various sources. Click on the
      <i
        className="pi pi-info-circle"
        style={{ fontSize: '1em', margin: '0 0.5em', verticalAlign: 'middle' }}
      ></i>
      NM Groundwater Dashboard button for more information.
      </>
    ),
    life: 12000,
    width: "30rem",
  });

  // ----------------- Render Component -----------------
  return (
    <>
    <Toast ref={toastRef} />
    <Toast ref={sessionToast} position="top-center"/>
    <NavDialog
        visible={showNavDialog}
        onHide={() => setShowNavDialog(false)}
        onConfirm={handleNavDialog}
        siteno={sitenoNav}
      />
      <GWPointsListDialog 
        visible={showPointsListDialog} 
        onHide={() => setShowPointsListDialog(false)} 
        points={pointsAtClick}
        onSelectPoint={handlePointSelection}
      />
    <div 
        style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 110px)",
        margin: 0,
        padding: 0,
      }}>
      <div style={{ flexGrow: 1 }}>
        <GWMap
            mapRef={mapRef}
            initialViewState={{ longitude: -106.4,
              latitude: 34.5,
              zoom: 6.5, }}
            style={{ width: "100%", height: "calc(100vh - 110px)", margin: 0, padding: 0 }}
            sources={sources}
            sourceData={sourceData}
            setSourceData={setSourceData}
            popupContent={popupContent}
            setPopupContent={setPopupContent}
            onMapClickCallback={onMapClick}
            selectedLocations={selectedLocations}
            toggleDataTable={toggleDataTable}
            onSelectedFeatureChange={handleSelectedFeatures}
          />
        </div>
    </div>

    {/* ----------- Data Table Sidebar ----------- */}
    <Sidebar
        visible={showDataTable}
        onHide={() => setShowDataTable(false)}
        position="right"
        style={{ width: sidebarWidth }}
      >
        <GWSelectedDataTable 
          selectedFeatures={selectedFeatures}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          onSelectionChange={(e) => setSelectedLocations(e.value)}
          handleDownload={handleDownload}
          downloadLoading={downloadLoading}
          toggleDataTable={toggleDataTable}
          setHydrographSelectedSite={setHydrographSelectedSite}
          setActiveTabIndex={setActiveTabIndex}
          plot_hygrogaphs={plot_hygrogaphs}
          setMultiHydrographSites={setMultiHydrographSites}
          setShowMultiHydrograph={setShowMultiHydrograph}
          setNmbgmrSelectedSite={setNmbgmrSelectedSite}
          nmbgmrLocations={nmbgmrLocations}
          setShowNmbgmrDialog={setShowNmbgmrDialog}
          //setBerncoSelectedSite={setBerncoSelectedSite}
          //setShowBerncoDialog={setShowBerncoDialog}
          fetchBerncoSiteData={fetchBerncoSiteData}
          fetchEbidSiteData={fetchEbidSiteData}
          activeTabIndex={activeTabIndex}
        />
        {/* <GWDataTable 
           enhancedLocations={enhancedLocations}
           selectedLocations={selectedLocations}
           setSelectedLocations={setSelectedLocations}
           onSelectionChange={(e) => setSelectedLocations(e.value)}
           handleDownload={handleDownload}
           downloadLoading={downloadLoading}
           toggleDataTable={toggleDataTable}
           setHydrographSelectedSite={setHydrographSelectedSite}
           setActiveTabIndex={setActiveTabIndex}
           plot_hygrogaphs={plot_hygrogaphs}
           setMultiHydrographSites={setMultiHydrographSites}
           setShowMultiHydrograph={setShowMultiHydrograph}
        /> */}
      </Sidebar>

      {/* -----------PVACD Hydrograph and Site Info Dialog ----------- */}
      <SiteInfoDialog 
        hydrographSelectedSite={hydrographSelectedSite}
        showHydrograph={showHydrograph}
        setShowHydrograph={setShowHydrograph}
        plotData={plotData}
        loading={loading}
        layout={layout}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        toggleDataTable={toggleDataTable}
        setMultiHydrographSites={setMultiHydrographSites}
        setShowMultiHydrograph={setShowMultiHydrograph}
        plot_hygrogaphs={plot_hygrogaphs}
        selectedLocations={selectedLocations}
      />

      {/* ----------- Bernalillo County Site Info Dialog ----------- */}
      <BERNCOSiteInfoDialog 
        berncoSelectedSite={berncoSelectedSite}
        showBerncoDialog={showBerncoDialog}
        setShowBerncoDialog={setShowBerncoDialog}
        berncoPlotData={berncoPlotData}
        berncoLoading={berncoLoading}
        layout={layout}
        setActiveTabIndex={setActiveTabIndex}
        activeTabIndex={activeTabIndex}
      />

      {/* ----------- EBID Site Info Dialog ----------- */}
      <EBIDSiteInfoDialog
        ebidSelectedSite={ebidSelectedSite}
        showEbidDialog={showEbidDialog}
        setshowEbidDialog={setShowEbidDialog}
        ebidPlotData={ebidPlotData}
        ebidLoading={ebidLoading}
        layout={layout}
        setActiveTabIndex={setActiveTabIndex}
        activeTabIndex={activeTabIndex}
      />

      {/* ----------- NM Bureau of Geology Site Info Dialog ----------- */}
      <NmbgmrSiteInfoDialog
        nmbgmrSelectedSite={nmbgmrSelectedSite}
        showNmbgmrDialog={showNmbgmrDialog}
        setShowNmbgmrDialog={setShowNmbgmrDialog}
        setActiveTabIndex={setActiveTabIndex}
        activeTabIndex={activeTabIndex}
      />

      {/* ----------- Multi-Site Hydrograph Dialog ----------- */}              
      <Dialog
        header="Multi-Site Hydrograph"
        visible={showMultiHydrograph}
        style={{ width: '80vw', height: '80vh' }}
        onHide={() => {
          setShowMultiHydrograph(false);
          setMultiHydrographSites([]);
          setMultiPlotData([]);
        }}
        maximizable
      >
        <div
          className="justify-content-center w-full relative"
        >
          <div className="absolute bottom-50 right-50 z-1">
            {multiLoading && <ProgressSpinner strokeWidth={5} />}
          </div>

          <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div style={{ flex: '3 1 0%', paddingRight: '1rem' }}>
            <Plot
              data={multiPlotData}
              layout={{
                ...layout,
                autosize: true,
                xaxis: {
                  ...layout.xaxis,
                  range: [new Date("2021-01-01"), new Date()],
                  autorange: false,
                },
                legend: {
                  orientation: 'h',
                  yanchor: 'top',
                  y: -0.2,
                  xanchor: 'center',
                  x: 0.5,
                },
              }}
              config={{ responsive: true }}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div style={{ flex: '1 1 0%', overflowY: 'auto' }}>
            <DataTable
              value={enhancedLocations}
              selection={selectedLocations}
              dataKey="id"
              onSelectionChange={(e) => {
                setSelectedLocations(e.value);
                setMultiHydrographSites(e.value);
                plot_hygrogaphs(e.value, 'multi');
              }}
              stripedRows
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
              <Column sortable field={"properties.name"} header={"Name"}></Column>
            </DataTable>
          </div>
        </div>
      </div>
      </Dialog>

    </>
  );
}
// ============= EOF =============================================
